var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-program"},[_c('main-app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.$attrs.id)?_c('span',[_vm._v(" "+_vm._s(_vm.program.name)+" ")]):_c('span',[_vm._v(" Add New Program ")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',[_c('arrow-button',{attrs:{"replace":"","variants":"","done":_vm.stage > 1,"to":{
            name: !_vm.isCompleted ? 'new.program.details' : 'program.details',
            params: { id: _vm.currentNewProgramId },
          },"arrow-right":""},domProps:{"textContent":_vm._s('Program Details')}}),_c('arrow-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasWorkoutDays),expression:"hasWorkoutDays"}],attrs:{"done":_vm.stage > 2,"disabled":!_vm.next,"to":{
            name: !_vm.isCompleted
              ? 'new.program.workout-days'
              : 'program.workout-days',
            params: { id: _vm.$attrs.id },
          },"arrow-left":"","arrow-right":"","replace":""},domProps:{"textContent":_vm._s('Workout Days')}}),_c('arrow-button',{attrs:{"replace":"","disabled":!_vm.next,"done":_vm.stage > 3,"to":{
            name: !_vm.isCompleted
              ? 'new.program.rich-description'
              : 'program.rich-description',
            params: { id: _vm.currentNewProgramId },
          },"arrow-left":"","arrow-right":""},domProps:{"textContent":_vm._s('Program Extra Information')}}),_c('arrow-button',{attrs:{"done":_vm.stage > 4,"disabled":!_vm.next,"to":{
            name: !_vm.isCompleted ? 'new.program.checkins' : 'program.checkins',
            params: { id: _vm.currentNewProgramId },
          },"arrow-left":"","arrow-right":"","replace":""},domProps:{"textContent":_vm._s('Program Check Ins')}}),_c('arrow-button',{attrs:{"done":_vm.stage > 5,"disabled":!_vm.next,"to":{
            name: !_vm.isCompleted ? 'new.program.variants' : 'program.variants',
            params: { id: _vm.currentNewProgramId },
          },"arrow-left":"","arrow-right":"","replace":""},domProps:{"textContent":_vm._s('Program Validity Variants')}}),_c('arrow-button',{attrs:{"done":_vm.stage > 6,"disabled":!_vm.next,"to":{
            name: !_vm.isCompleted ? 'new.program.videos' : 'program.videos',
            params: { id: _vm.currentNewProgramId },
          },"arrow-left":"","arrow-right":"","replace":""},domProps:{"textContent":_vm._s('Program Videos')}}),_c('arrow-button',{attrs:{"done":_vm.stage > 7,"disabled":!_vm.next,"to":{
            name: !_vm.isCompleted ? 'new.program.workouts' : 'program.workouts',
            params: { id: _vm.currentNewProgramId },
          },"arrow-left":"","arrow-right":_vm.hasDeload,"replace":""},domProps:{"textContent":_vm._s('Program Workouts')}}),(_vm.hasDeload)?_c('arrow-button',{attrs:{"done":_vm.stage > 8,"disabled":!_vm.next,"to":{
            name: !_vm.isCompleted
              ? 'new.program.deload.workouts'
              : 'program.deload.workouts',
            params: { id: _vm.currentNewProgramId },
          },"arrow-left":"","replace":""},domProps:{"textContent":_vm._s('Program Deload Workouts')}}):_vm._e()],1)]},proxy:true}])}),_c('div',{staticClass:"mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8"},[(!_vm.loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1):_c('content-loader',{attrs:{"loading":_vm.loading,"alias":"Program"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }