<template>
  <div class="new-program">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id"> {{ program.name }} </span>
        <span v-else> Add New Program </span>
      </template>

      <template v-slot:actions>
        <div>
          <arrow-button
            v-text="'Program Details'"
            replace
            variants
            :done="stage > 1"
            :to="{
              name: !isCompleted ? 'new.program.details' : 'program.details',
              params: { id: currentNewProgramId },
            }"
            arrow-right
          />
          <arrow-button
            v-show="hasWorkoutDays"
            v-text="'Workout Days'"
            :done="stage > 2"
            :disabled="!next"
            :to="{
              name: !isCompleted
                ? 'new.program.workout-days'
                : 'program.workout-days',
              params: { id: $attrs.id },
            }"
            arrow-left
            arrow-right
            replace
          />
          <arrow-button
            v-text="'Program Extra Information'"
            replace
            :disabled="!next"
            :done="stage > 3"
            :to="{
              name: !isCompleted
                ? 'new.program.rich-description'
                : 'program.rich-description',
              params: { id: currentNewProgramId },
            }"
            arrow-left
            arrow-right
          />

          <arrow-button
            v-text="'Program Check Ins'"
            :done="stage > 4"
            :disabled="!next"
            :to="{
              name: !isCompleted ? 'new.program.checkins' : 'program.checkins',
              params: { id: currentNewProgramId },
            }"
            arrow-left
            arrow-right
            replace
          />

          <arrow-button
            v-text="'Program Validity Variants'"
            :done="stage > 5"
            :disabled="!next"
            :to="{
              name: !isCompleted ? 'new.program.variants' : 'program.variants',
              params: { id: currentNewProgramId },
            }"
            arrow-left
            arrow-right
            replace
          />

          <arrow-button
            v-text="'Program Videos'"
            :done="stage > 6"
            :disabled="!next"
            :to="{
              name: !isCompleted ? 'new.program.videos' : 'program.videos',
              params: { id: currentNewProgramId },
            }"
            arrow-left
            arrow-right
            replace
          />
          <arrow-button
            v-text="'Program Workouts'"
            :done="stage > 7"
            :disabled="!next"
            :to="{
              name: !isCompleted ? 'new.program.workouts' : 'program.workouts',
              params: { id: currentNewProgramId },
            }"
            arrow-left
            :arrow-right="hasDeload"
            replace
          />

          <arrow-button
            v-if="hasDeload"
            v-text="'Program Deload Workouts'"
            :done="stage > 8"
            :disabled="!next"
            :to="{
              name: !isCompleted
                ? 'new.program.deload.workouts'
                : 'program.deload.workouts',
              params: { id: currentNewProgramId },
            }"
            arrow-left
            replace
          />
        </div>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in" v-if="!loading">
        <router-view />
      </transition>
      <content-loader :loading="loading" alias="Program" v-else />
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import ContentLoader from '@/components/elements/loaders/ContentLoader'

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'AddNewProgram',

  components: {
    MainAppBar,
    ArrowButton,
    ContentLoader,
  },

  data() {
    return {
      loading: false,
      program: {},
      icons: {
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapState({
      selectedProgram: (state) => state.programs.selectedProgram,
    }),

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },

    next() {
      return !!this.$attrs.id
    },

    currentNewProgramId() {
      return this.$route.params.id
    },

    isCompleted() {
      return this.selectedProgram.completed_at
    },

    isCreated() {
      return !!this.selectedProgram.id
    },

    hasDeload() {
      return this.selectedProgram.has_deload
    },
    hasWorkoutDays() {
      return this.selectedProgram.has_workout_days
    },
  },

  created() {
    let programId = this.$attrs.id

    if (programId) {
      this.fetchProgram(programId)
    }
  },

  methods: {
    ...mapActions({
      getProgram: 'programs/getProgram',
      deleteProgram: 'programs/deleteProgram',
    }),

    ...mapMutations({
      clearSelectedProgram: 'programs/clearSelectedProgram',
    }),

    async fetchProgram(programId) {
      this.loading = true
      let { data } = await this.getProgram(programId)
      this.loading = false
      this.program = data
    },

    async deleteCurrentProgram() {
      // await this.deleteProgram(this.selectedProgram).then(res => {
      //   console.log('deleted')
      // })
    },
  },

  beforeRouteLeave: function(to, from, next) {
    this.clearSelectedProgram()

    next()
  },

  watch: {
    selectedProgram(program) {
      this.program = program
    },
  },
}
</script>
